.post-summary {
  position: relative;
}

.post-summary .short-content {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
  background: #5B86E5;
  color: white;
  border-radius: 4px;
  text-align: left;
  padding: 4px 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.post-summary .short-content.error {
  background: var(--mdb-danger);
}
.post-summary .short-content.published {
  background: var(--mdb-success);
}

.short-content {
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.post-preview {
  position: absolute;
  z-index: 5;
  max-width: 300px;
  border: 1px solid;
  border-radius: 4px;
}

.top-left {
  top: -8px;
  left: 105%;
}
.bottom-left {
  bottom: 8px;
  left: 105%;
}

.top-right {
  top: -8px;
  right: 105%;
}
.bottom-right {
  bottom: 8px;
  right: 105%;
}