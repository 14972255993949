.sign-in-page {
  height: 100vh;
}

.left {
  background-size: cover;
  background-position: center;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-in-form {
  max-width: 400px;
  width: 100%;
  padding: 40px;
  border-radius: 4px;
}

.sign-in-form h2 {
  margin-top: 0;
  margin-bottom: 32px;
}
