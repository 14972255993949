#attachment-file {
  line-height: 2rem;
  border-top: none;
  border-radius: 0 0 0 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}

#attachment-preview {
  position: relative;
  border: 1px solid #AAA;
  border-top: none;
}

#attachment-preview img {
  max-width: 100%;
  display: block;
  margin: auto;
}

#attachment-preview .attachment-btn-wrapper .btn {
    width: 32px;
    height: 32px;
    padding: 0;
    line-height: 0;
}
#attachment-preview .attachment-btn-wrapper {
  display: none;
  position: absolute;
  right: 8px;
  top: 8px;
}

#attachment-preview:hover .attachment-btn-wrapper {
  display: inline-block;
}

#attachment-setup {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fffe;
    padding: 32px;
    backdrop-filter: blur(8px);
    z-index: 5;
}