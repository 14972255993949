#sidebar {
  position: fixed;
  width: var(--sidebar-width);
  z-index: 15;
  height: 100%;
  background: var(--main-bg-color);
}

#logo {
  height: var(--navbar-height);
}

#logo img {
  max-width: 100%;
  max-height: 100%;
}

#sidebar .list-group {
  border-radius: 0;
}

#sidebar .list-group-item {
  border: none;
  transition: all 0.25s ease;
  cursor: pointer;
  font-weight: bold;
  border-left: 8px solid transparent;
}

#sidebar .list-group-item:hover {
  color: white;
  background: var(--secondary-bg-color);
}

#sidebar .drawer-btn {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  font-size: 18px;
}

.new-post-btn .btn {
  font-size: 16px;
}