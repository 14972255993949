@font-face {
  font-family: 'IstokWeb-Regular';
  src: local('IstokWeb-Regular'), url(../public/assets/fonts/IstokWeb-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../public/assets/fonts/Inter.ttf) format('truetype');
}

html, body, #root, .app {
  height: 100%;
}

.app {
  background: var(--main-bg-color);
  color: var(--primary-text-color);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
}

:root {
  --sidebar-width: 250px;
  --navbar-height: 60px
}

:root {
  --mdb-primary: #6a26ff; /* Your desired primary color */
  --mdb-primary-rgb: 106, 38, 255;
  --mdb-danger: #f41f42;
  --mdb-danger-rgb: 244, 31, 66;
  --cta-color: #6aff00;
  --dark-purple: #190B37;
  --purple: #3800A6;
}

.bg-primary {
  background: var(--mdb-primary) !important;
}

.bg-purple {
  background: var(--purple);
}

.bg-dark-purple {
  background: var(--dark-purple);
}

.light-theme {
  --main-bg-color: white;
  --secondary-bg-color: var(--mdb-primary);
  --primary-text-color: #232323;
  --secondary-text-color: #fff;
}

.dark-theme {
  --main-bg-color: #1f283e;
  --secondary-bg-color: #1a2035;
  --primary-text-color: #fff;
  --secondary-text-color: #232323;
}

.shadow-custom {
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.25);
}

.hashtag {
  color: var(--secondary-bg-color);
}

select {
  background: none;
  border: 1px solid #bdbdbd;
  padding: 8px;
  border-radius: 4px;
}

/* Tags input */

.react-tagsinput {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  cursor: text;
  padding-right: 5px;
}

.react-tagsinput-tag {
  background: var(--secondary-bg-color);
  color: white;
  border-radius: 32px;
  font-size: 16px;
  border: none;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  padding: 0 26px 0 12px;
  white-space: nowrap;
  vertical-align: top;
}

.react-tagsinput-remove {
  position: absolute;
  padding: 0 10px;
  right: 0;
}

.react-tagsinput-remove,
.react-tagsinput-remove:hover {
  color: white;
}

.react-tagsinput-input {
  color: black;
  font-size: 16px;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.linkedin-color {
  color: #0077b5;
}

body input {
  color: black;
  font-weight: bold;
}

.fs-7 {
  font-size: 0.8rem !important;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background: var(--mdb-primary);
  box-shadow: 0 4px 9px -4px #844bff;
}

.btn-primary:disabled,
.btn-primary.disabled {
  opacity: 0.35;
  background: var(--mdb-primary);
  box-shadow: 0 4px 9px -4px rgba(132, 75, 255, 0.25);
}
.btn-group.disabled {
  box-shadow: 0 4px 9px -4px rgba(132, 75, 255, 0.25);
}

.btn-outline-primary,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:hover {
  border-color: var(--mdb-primary);
  color: var(--mdb-primary);
}

.list-group-item.active {
  background: #e5dff6;
  color: var(--mdb-primary);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: white;
}

.btn.btn-outline-tertiary:disabled,
.btn.btn-outline-tertiary.disabled {
  background: none;
  color: #a4a4a4;
}

.btn-group,
.btn-group-vertical {
  box-shadow: 0 4px 9px -4px #844bff;
}

.cta-btn,
.cta-btn:active,
.cta-btn:focus {
  background: #6aff00;
  color: var(--primary-text-color);
}

.cta-btn:hover {
  background: #7aee2b;
  color: var(--primary-text-color);
}

.cta-btn {
  box-shadow: 0 4px 9px -4px #6de41b;
}

.text-cta {
  color: var(--cta-color);
}

.btn-with-loader:disabled {
}

.btn-with-loader .loader-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.btn-with-loader .loader-icon i {
  animation: rotate 1s linear infinite; /* Adjust the duration and timing function as needed */
}

#small-screen-disclaimer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
  left: 0;
  background: var(--purple);
  display: none;
  align-items: center;
}

#small-screen-disclaimer .text {
  margin: auto;
  max-width: 350px;
  text-align: center;
}

.w-space-pre-line {
  white-space: pre-line;
}

@media (max-width: 820px) {

  body, html, .container, .app {
    overflow: hidden;
  }

  #small-screen-disclaimer {
    display: flex;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
