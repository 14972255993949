.connect-social-media {
  border: 1px solid;
  border-radius: 24px;
  margin-left: 16px;
  font-size: 14px;
  height: 46px;
  min-width: 150px;
  text-align: center;
}

.connect-social-media button {
  margin-left: 8px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  padding: 0;
}

.connect-social-media.connected {
  display: flex;
  align-items: center;
  border-color: var(--mdb-success);
  color: var(--mdb-success);
  padding: 2px 2px 2px 16px;
}

.provider-name {
  flex: auto
}