.full-post {
  text-align: left;
}

.full-post .card-body {
  padding: 16px;
}

.message-wrapper {
  display: inline-block;
  max-height: 160px;
  overflow: auto;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  font-size: 14px;
}

.attachment-image {
  aspect-ratio: 16/9;
  background-size: cover;
  background-position: center;
}

.card-btn.btn {
  border-radius: 0;
  width: 72px;
  color: #999;
}

.card-btn i {
  font-size: 16px;
}

.card-btn span {
  margin-top: 2px;
  font-size: 10px;
}

.card-btn.btn:hover,
.card-btn.btn:focus:active {
  background: var(--secondary-bg-color);
  color: var(--secondary-text-color);
}

.card-btn.delete:hover,
.card-btn.delete:focus:active {
  background: var(--mdb-danger);
  color: var(--secondary-text-color);
}

.card-footer-left {
  line-height: 1.2em;
}

.post-status {
  border-radius: 24px;
  font-size: 14px;
  margin-bottom: 4px;
  display: inline-block;
  font-weight: bold;
}

.scheduled .post-status {
  color: var(--secondary-bg-color);
}

.published .post-status {
  color: var(--mdb-success);
}

.error .post-status {
  color: var(--mdb-danger);
}

.published-on {
  float: right;
  line-height: normal;
}

.published-on:nth-child(2n+1) {
  clear: both;
}