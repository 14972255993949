.timeline-group {
    padding: 16px 0;
}

.timeline-group:first-child {
    padding-top: 0;
}

.timeline-group:last-child {
    padding-bottom: 0;
}

.timeline-ribbon {
    width: 16px;
    background: #e5dff6;
    border-radius: 4px;
    margin: 0 4px 0;
    padding: 0;
}

.timeline-date {
    text-transform: capitalize;
    position: relative;
    display: inline-block;
    margin-bottom: 16px;
    background: #e5dff6;
    color: var(--mdb-primary);
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: bold;
}

.timeline-date::before {
    content: '';
    width: 8px;
    height: 8px;
    background: #e5dff6;
    display: inline-flex;
    border-radius: 100%;
    top: 50%;
    position: absolute;
    left: -12px;
    transform: translateY(-50%);
}