#calendar {
    overflow: hidden;
}

#calendar th {
    text-align: center;
    height: 60px;
}

.calendar-cell {
    width: 14.28%;
    max-width: 100px;
    border: 1px solid #ddd;
    text-align: center;
    vertical-align: top;
    padding: 8px;
    height: 150px;
    position: relative;
}

.calendar-cell .day-label {
    margin-bottom: 8px;
}

#calendar th:nth-child(7) .day-label,
.calendar-cell:nth-child(7) .day-label {
    color: red;
    font-weight: bold;
}

.calendar-cell.previous-month {
    border: none;
}

.calendar-cell .add-post {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    box-shadow: none;
    color: #999;
    background: transparent;
}
.calendar-cell .add-post:hover {
    background: var(--secondary-bg-color);
    color: var(--secondary-text-color);
}

.calendar-cell:not(.previous-month) .day-label {
    display: inline-block;
    height: 27px;
    vertical-align: middle;
    width: 100%;
    border-bottom: 1px solid #eee;
}

.calendar-selector:first-child {
    border-right: 1px solid #999;
}
