#homepage {
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

#homepage img {
  max-width: 100%;
}

nav {
  padding: 16px 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  transition: all 0.25s ease;
  background-color: transparent;
}

#hero {
  background: rgb(42,6,122);
  background: -moz-linear-gradient(180deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
  background: linear-gradient(180deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2a067a",endColorstr="#6a26ff",GradientType=1);
  height: 100vh;
  position: relative;
  max-height: 850px;
  min-height: 750px;
}

.catchLine {
  z-index: 5;
}

.catchLine h1 {
  font-size: 40px;
  font-weight: 700;
  max-width: 595px;
  padding: 40px 0 0;
}

.btn-primary.cta-btn {
  font-size: 16px;
  box-shadow: 0 4px 6px 0 #00000040;
}

.btn-primary.cta-btn:hover {
  box-shadow: 0 0 0 0 #000;
}

.parallax-shape {
  height: 330px;
  width: 100%;
  min-width: 1100px;
  padding: 0;
  position: absolute;
  z-index: 0;
  bottom: -2px;
  left: 0;
  background-repeat: repeat-x;
  background-size: contain;
  animation: backgroundTranslate 600s linear infinite;
}

.parallax-shape.top-shape {
  top: 56px;
  left: 0;
  animation: backgroundTranslate 5000s linear infinite;
  height: 20%;
  background-repeat: repeat;
}


.extend {
  width: 1000px;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
}

nav #logo {
  padding: 8px 0;
  transition: all 0.25s ease;
}

.shrinked {
  padding-top: 0;
  padding-bottom: 0;
  background-color: var(--dark-purple);
}

.shrinked #logo {
  padding-top: 16px;
  padding-bottom: 16px;
}

.main-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 80px;
}

.rocket {
  position: relative;
  animation: bounce 500ms alternate infinite ease-in-out;
}

.floating-logos {
  height: 135px;
  width: 100%;
  min-width: 1100px;
  padding: 0;
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
}

#homepage .floating-logos .social-logo-wrapper {
  max-width: 124px;
  position: absolute;
}

#homepage .floating-logos .social-logo-wrapper .social-media-logo {
  animation: bounce 500ms alternate infinite ease-in;
  position: relative;
  box-shadow: 0 4px 6px 0 #00000040;
  border-radius: 100%;
}

#homepage .floating-logos .social-logo-wrapper:nth-child(1) {
  transform: translate(0, -80px) scale(1) rotateZ(-15deg);
  left: 5%;
}

#homepage .floating-logos .social-logo-wrapper:nth-child(2) {
  transform: translate(0, -20px) scale(0.85) rotateZ(13deg);
  left: 33%;
}

#homepage .floating-logos .social-logo-wrapper:nth-child(3) {
  transform: translate(0, -70px) scale(0.5) rotateZ(-15deg);
  left: 50%;
}

#homepage .floating-logos .social-logo-wrapper:nth-child(4) {
  transform: translate(0, -10px) scale(0.65) rotateZ(-18deg);
  left: 65%;
}

#homepage .floating-logos .social-logo-wrapper:nth-child(1) .social-media-logo {
  animation-delay: 100ms;
  animation-duration: 450ms;
}

#homepage .floating-logos .social-logo-wrapper:nth-child(2) .social-media-logo {
  animation-delay: -1000ms;
  animation-duration: 450ms;
}

#homepage .floating-logos .social-logo-wrapper:nth-child(3) .social-media-logo {
  animation-delay: -2500ms;
  animation-duration: 450ms;
}

#homepage .floating-logos .social-logo-wrapper:nth-child(4) .social-media-logo {
  animation-delay: -1300ms;
  animation-duration: 450ms;
}

#homepage .floating-logos .cloud {
  position: absolute;
  bottom: 0;
  max-width: none;
  left: 0;
  height: 100%;
  width: 200%;
}

#homepage .floating-logos .cloud.left {
  transform: translate(-65%, 60%);
}

#homepage .floating-logos .cloud.right {
  transform: translate(-20%, 90%);
}

#homepage .feature-icon {
  max-width: 140px;
  margin-bottom: 16px;
}

#homepage .feature h3 {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 16px;
}

#homepage .feature .description {
  line-height: 1.2em;
}

#homepage .feature .footer {
  font-weight: bold;
}

#homepage .feature img {
  max-width: 90%;
}

#pricing-section {
  background: #6A26FF;
}

#pricing-section .parallax-shape {
  transform: rotateZ(180deg) scaleX(-1);
  position: relative !important;
  bottom: auto;
  top: -2px;
}

@keyframes backgroundTranslate {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: -2000vw 100%;
  }
}

@keyframes bounce {
  from {
    top: 0;
  }
  to {
    top: 8px;
  }
}