.social-profile-info {
    width: calc(100% - 64px);
}

.profile-name,
.profile-description {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    overflow: hidden;
}