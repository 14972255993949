.user-logo {
  width: 72px;
  height: 72px;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #eee;
  border-radius: 8px;
}

.ant-color-picker {
  z-index: 1500;
}

.brand-logo-wrapper {
  padding: 0 12px;
}

.brand-logo-wrapper > .col {
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: 10px;
}

#brand-logo {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  top: 0;
  left: 0;
}