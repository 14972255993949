#header {
  position: fixed;
  width: calc(100% - var(--sidebar-width));
  left: var(--sidebar-width);
  z-index: 10;
  height: var(--navbar-height);
  background: var(--secondary-bg-color);
}

.i18n-flag {
  width: 24px;
}