/* PricingPage.css */
#pricing.dark {
    background: rgb(42,6,122);
    background: -moz-linear-gradient(0deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
    background: linear-gradient(0deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6a26ff",endColorstr="#2a067a",GradientType=1);

}

.dark .pricing-card {
    color: var(--primary-text-color);
}

.dark .plans {
    background: white;
}

.pricing-card {
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    text-align: center;
    color: var(--secondary-text-color);
}

.pricing-card h3 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
}

.plans {
    background: linear-gradient(0deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
}

.price {
    font-size: 48px;
    font-weight: bold;
    margin: 0;
}

.unit {
    font-size: 32px;
}

.billing-info {
    margin-bottom: 32px;
    display: inline-block;
}

.pricing-card .btn {
    width: 100%;
    font-weight: bold;
}

.features {
    padding: 0;
    list-style: none;
    margin-top: 32px;
}

.payment-callback {
}

.payment-callback .icon {
    font-size: 80px;
    position: relative;
    z-index: 5;
    background: white;
    border-radius: 100%;
}

.circles {
    height: 240px;
    position: absolute;
    width: 240px;
    top: -100%;
    left: -100%;
    transform: translate(0%, 0%);
    z-index: 1;
}
.circles > div {
    animation: growAndFade 3s infinite ease-out;
    background-color: var(--mdb-success);
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
}
.circles .circle1 {
    animation-delay: -1s;
}
.circles .circle2 {
    animation-delay: 0s;
}
.circles .circle3 {
    animation-delay: 1s;
}

.current-plan.btn-primary,
.current-plan.btn-primary:disabled {
    opacity: 1;
    box-shadow: none;
    border-radius: 8px;
    background: none;
    color: white;
    border: 2px solid var(--cta-color);
}

.dark .current-plan.btn-primary,
.dark .current-plan.btn-primary:disabled {
    color: var(--primary-text-color);
}

.dark h2 {
    color: white;
}

@keyframes growAndFade {
    0% {
        opacity: 0.25;
        transform: scale(0);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}