.radio-selectors label {
  font-size: 32px;
}

.radio-selectors .btn-check + label {
  padding: 0;
  box-sizing: border-box;
  transition: transform 0.25s ease, background 0.35s ease;
  height: 100%;
}

.radio-selectors .btn-check:active + label {
  border: none;
}

.radio-selectors .btn-check:checked + label .avatar-wrapper {
  border-color: transparent;
}

.radio-selectors .btn-check:checked + label {
  font-size: 32px;
  background: none;
  border: none;
  transform: scale(105%);
}

.social-media-card {
  height: 100%;
}

.post-tone select {
  width: 100%;
}

.avatar-wrapper {
  padding: 24px 8px;
  border: 1px solid #eee;
  border-radius: 4px;
  height: 100%;
}

.avatar-pic {
  background: #f5f5f5;
  width: 80px;
  height: 80px;
  display: flex;
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid #fff;
  margin: auto;
  overflow: hidden;
}

.profile-text {
  font-size: 16px;
  line-height: 1.2em;
  display: inline-block;
  margin-top: 16px;
  text-transform: none;
  vertical-align: top;
  color: var(--primary-text-color);
}

.avatar-pic img {
  max-width: 100%;
}

.radio-selectors .btn-check:checked + label {
  background: #8410E9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom right, #8410E9, #506AFF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom right, #8410E9, #506AFF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: 0 0 16px -8px #8410E9;
}

.radio-selectors .btn-check:checked + label .profile-text {
  color: var(--secondary-text-color);
}

.avatar-pic i {
  margin: auto;
}
