.side-view {
  background: rgb(42,6,122);
  background: -moz-linear-gradient(180deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
  background: linear-gradient(180deg, rgba(42,6,122,1) 0%, rgba(106,38,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2a067a",endColorstr="#6a26ff",GradientType=1);
}

.side-view img {
  max-width: 250px;
}

.sign-up-page {
  height: 100vh;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-up-form {
  max-width: 400px;
  width: 100%;
  padding: 40px;
  border-radius: 4px;
}

.sign-up-form h2 {
  margin-top: 0;
  margin-bottom: 32px;
}
