.more-settings {
    text-align: center;
    position: relative;
    cursor: pointer;
    width: 90%;
    margin: auto;
}

.more-settings .btn {
    background: white;
    box-shadow: none !important;
    color: var(--mdb-gray-600);
    border: none;
}

.more-settings:hover .btn {
    color: var(--secondary-bg-color);
    box-shadow: 0 0 0 8px white;
}

.more-settings::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background: var(--mdb-gray-400);
    left: 50%;
    top: 45%;
    transform: translate(-50%);
    transition: background-color .15s ease-in-out;
}

.more-settings:hover::before {
    background: var(--secondary-bg-color);
}

.range-wrapper {
    position: relative;
}

.range-wrapper .form-label {
    margin-bottom: 0;
    color: var(--primary-text-color);
}

.range-wrapper input {
    cursor: pointer;
}

.low-label,
.mid-label,
.high-label {
    position: absolute;
    bottom: -8px;
    font-size: 12px;
}

.mid-label {
    left: 50%;
    transform: translate(-50%, 0);
}

.high-label {
    right: 0;
}

.private-field i {
    position: absolute;
    top: 8px;
    right: 16px;
    color: var(--secondary-bg-color);
}
