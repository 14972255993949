.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--mdb-primary);
    left: 0;
    top: 0;
    z-index: 5;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    backdrop-filter: blur(8px);
    border-radius: .5rem;
    padding: 16px;
    transition: opacity 250ms ease;
    opacity: 0;
}

.loader.isVisible {
    opacity: 1;
}

.loader.isHidden {
    opacity: 0;
    pointer-events: none;
}

.drawing {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: block;
    background-color: #3a3a3a;
}

.loading-quote {
    position: absolute;
    left: 0;
    padding: 32px;
    top: 52%;
    width: 100%;
    -webkit-animation: quote-animation 5s infinite ease-in-out;
    -moz-animation: quote-animation 5s infinite ease-in-out;
    -o-animation: quote-animation 5s infinite ease-in-out;
    animation: quote-animation 5s infinite linear;
}

/* -- Loading thingy --*/
.loading-dot {
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background-color: #ffffff;
    margin: 0 auto 80px;
    box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff,-40px 0 0 0 #ffffff,
    40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    -webkit-animation: load 3s infinite linear;
    -moz-animation: load 3s infinite linear;
    -o-animation: load 3s infinite linear;
    animation: load 3s infinite linear;
}

@keyframes quote-animation {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: translate(0px, 0), rotate(0deg);
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

    25% {
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        -40px -40px 0 0 #ffffff, 40px 40px 0 0 #ffffff, -40px 40px 0 0 #ffffff, 40px -40px 0 0 #ffffff;
    }

    50% {
        box-shadow: 0 -40px 0 0 #ffffff, 0 40px 0 0 #ffffff, -40px 0 0 0 #ffffff, 40px 0 0 0 #ffffff,
        -40px -40px 0 0 #ffffff, 40px 40px 0 0 #ffffff, -40px 40px 0 0 #ffffff, 40px -40px 0 0 #ffffff;
    }

    70% {
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
    }

    75% {
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        height: 10px;
        width: 10px;
    }

    80% {
        -webkit-transform: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 40px;
        width: 40px;
    }

    85% {
        -webkit-transform: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 40px;
        width: 40px;
    }

    90% {
        -webkit-transform: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 10px;
        width: 10px;
    }

    95% {
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

    100% {
        -webkit-transform: rotate(360deg);
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

}

@-moz-keyframes load {
    0% {
        -moz-transform: translate(0px, 0), rotate(0deg);
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

    25% {
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        -40px -40px 0 0 #ffffff, 40px 40px 0 0 #ffffff, -40px 40px 0 0 #ffffff, 40px -40px 0 0 #ffffff;
    }

    50% {
        box-shadow: 0 -40px 0 0 #ffffff, 0 40px 0 0 #ffffff, -40px 0 0 0 #ffffff, 40px 0 0 0 #ffffff,
        -40px -40px 0 0 #ffffff, 40px 40px 0 0 #ffffff, -40px 40px 0 0 #ffffff, 40px -40px 0 0 #ffffff;
    }

    70% {
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
    }

    75% {
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        height: 10px;
        width: 10px;
    }

    80% {
        -moz-transform: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 40px;
        width: 40px;
    }

    85% {
        -moz-transform: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 40px;
        width: 40px;
    }

    90% {
        -moz-transform: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 10px;
        width: 10px;
    }

    95% {
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

    100% {
        -moz-transform: rotate(360deg);
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

}

@keyframes load {
    0% {
        transform: translate(0px, 0), rotate(0deg);
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

    25% {
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        -40px -40px 0 0 #ffffff, 40px 40px 0 0 #ffffff, -40px 40px 0 0 #ffffff, 40px -40px 0 0 #ffffff;
    }

    50% {
        box-shadow: 0 -40px 0 0 #ffffff, 0 40px 0 0 #ffffff, -40px 0 0 0 #ffffff, 40px 0 0 0 #ffffff,
        -40px -40px 0 0 #ffffff, 40px 40px 0 0 #ffffff, -40px 40px 0 0 #ffffff, 40px -40px 0 0 #ffffff;
    }

    70% {
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
    }

    75% {
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        height: 10px;
        width: 10px;
    }

    80% {
        transform: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 40px;
        width: 40px;
    }

    85% {
        transform: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 40px;
        width: 40px;
    }

    90% {
        transform: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 10px;
        width: 10px;
    }

    95% {
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

    100% {
        transform: rotate(360deg);
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

}

@-o-keyframes load {
    0% {
        -o-transforms: translate(0px, 0), rotate(0deg);
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

    25% {
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        -40px -40px 0 0 #ffffff, 40px 40px 0 0 #ffffff, -40px 40px 0 0 #ffffff, 40px -40px 0 0 #ffffff;
    }

    50% {
        box-shadow: 0 -40px 0 0 #ffffff, 0 40px 0 0 #ffffff, -40px 0 0 0 #ffffff, 40px 0 0 0 #ffffff,
        -40px -40px 0 0 #ffffff, 40px 40px 0 0 #ffffff, -40px 40px 0 0 #ffffff, 40px -40px 0 0 #ffffff;
    }

    70% {
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
    }

    75% {
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        height: 10px;
        width: 10px;
    }

    80% {
        -o-transforms: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 40px;
        width: 40px;
    }

    85% {
        -o-transforms: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 40px;
        width: 40px;
    }

    90% {
        -o-transforms: translate(0px, 0) rotate(360deg);
        box-shadow: 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff,
        0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff, 0 0 0 0 #ffffff;
        background-color: #ffffff;
        height: 10px;
        width: 10px;
    }

    95% {
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

    100% {
        -o-transforms: rotate(360deg);
        box-shadow: 0 40px 0 0 #ffffff, 0 -40px 0 0 #ffffff, 40px 0 0 0 #ffffff, -40px 0 0 0 #ffffff,
        40px 40px 0 0 #ffffff, -40px -40px 0 0 #ffffff, 40px -40px 0 0 #ffffff, -40px 40px 0 0 #ffffff;
    }

}