#post-preview {
  border: 1px solid #AAA;
  padding: 16px;
  border-radius: 4px 4px 0 0;
}

#post-header {
  display: flex;
  margin-bottom: 16px;
}

#post-header img {
  max-height: 56px;
  border-radius: 100%;
}

.social-profile-info {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
}

.social-profile-info > span {
  line-height: 1.2em;
  font-size: 0.9rem;
}

.post-schedule-selectors .schedule-selector:first-child label {
  border-radius: 8px 0 0 8px;
}

.post-schedule-selectors .schedule-selector:last-child label {
  border-radius: 0 8px 8px 0;
}

.post-schedule-selectors .schedule-selector input:checked + label {
  background: var(--secondary-bg-color);
  color: var(--secondary-text-color);
}

.scheduler-date {
  flex: auto;
}

ul.post-schedule-type li {
  background: #d1e1ff;
  color: var(--primary-text-color);
  font-weight: bold;
  padding: 8px 16px;
  cursor: pointer;
}

ul.post-schedule-type li:active,
ul.post-schedule-type li:hover {
  background: var(--mdb-primary);
  color: white;
}

.dropdown button.post-button {
  border-right: 1px solid white;
  z-index: 2;
}

.dropdown button.post-button:focus,
.dropdown button.post-button:active,
.dropdown button.post-button:hover {
  z-index: 2;
}

.dropdown .dropdown-toggle.disabled {
  opacity: 0.65;
  background: #3b71ca;
  pointer-events: none;
}

.dropdown-menu.post-schedule-type {
  transform: translate(0, 46px) !important;
}

#scheduler-date {
  border: 1px solid #999;
}

.vanilla-calendar-input-wrapper .vanilla-calendar_to-input {
  position: absolute;
  right: 105%;
  top: -260px;
  left: auto;
}

#preview-textarea textarea {
    min-height: 200px;
}

#preview-textarea textarea,
#auto-comment-textarea {
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}

#auto-comment-textarea {
  border: 1px solid #AAA;
  padding: 16px;
  margin: 16px 0 0;
  border-radius: 4px;
  min-height: 56px;
}

em-emoji-picker {
  max-height: 260px;
  position: absolute;
  z-index: 5;
  right: 56px;
  bottom: 40px;
  transform: translate(0, 100%);
}

#emoji-picker {
  position: relative;
  text-align: right;
}

#emoji-picker-toggler {
  border: 1px solid #999;
  border-top: none;
  border-left: none;
  border-radius: 0 0 4px 0;
  padding: 8px 0;
  width: 100%;
}

.dropdown-menu.animation {
  animation-duration: 0.15s;
}

.form-outline #scheduler-date + .form-notch {
  display: none;
}
